import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import NProgress from 'nprogress'

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-family: 'Nunito Sans', sans-serif;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}  
`

const Layout = props => {
  useEffect(() => {
    // NProgress
    // Router.onRouteChangeStart = url => {
    //   console.log(url)
    //   NProgress.start()
    // }
    // Router.onRouteChangeComplete = () => NProgress.done()
    // Router.onRouteChangeError = () => NProgress.done()
  }, [])
  return (
    <>
      {props.children}
      <GlobalStyle />
    </>
  )
}

export default Layout
